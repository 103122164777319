import React, { useEffect, useState } from 'react';
import AllUsers from './allUsers';
import SendMailToAll from './sendMailToAll';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MasterApplications from './masterApplications';

const limit = 10;
export default function AdminPanel({usrs, getUsers, approveUser, setKycUserAdmin, openKYCFormAdmin, setPaidStatus}){
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const authedUser = useSelector(s => s.authedUser);
  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL+"/users/validate-jwt", {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authedUser?.authedUser?.token}`
      }
    }).then(r => {
      if (r.ok){
        return r.json();
      }
      else{
        return {success: false}
      }
    }).then(r => {
      if (r.success){
        if (!r.user.admin){
          localStorage.removeItem('token');
          history.push("/login");  
        }
      }
      else{
        localStorage.removeItem('token');
        history.push("/login");
      }
    });
  }, []);

  return(
    <>
      <h4 className='text-center'>Copytrade Admin Panel</h4>
      <div className='admin-tabs'>
        <div className='admin-tab' style={tab === 0 ? {border: '1px solid white'} : {}} onClick={() => setTab(0)}>All Users</div>
        <div className='admin-tab' style={tab === 7 ? {border: '1px solid white'} : {}} onClick={() => setTab(7)}>Special Users</div>
        <div className='admin-tab' style={tab === 2 ? {border: '1px solid white'} : {}} onClick={() => setTab(2)}>Paid Users</div>
        <div className='admin-tab' style={tab === 3 ? {border: '1px solid white'} : {}} onClick={() => setTab(3)}>Approved Users</div>
        <div className='admin-tab' style={tab === 4 ? {border: '1px solid white'} : {}} onClick={() => setTab(4)}>Unpaid Users</div>
        <div className='admin-tab' style={tab === 5 ? {border: '1px solid white'} : {}} onClick={() => setTab(5)}>Unapproved Users</div>
        <div className='admin-tab' style={tab === 8 ? {border: '1px solid white'} : {}} onClick={() => setTab(8)}>Unapproved Users (KYC Submitted)</div>
        <div className='admin-tab' style={tab === 1 ? {border: '1px solid white'} : {}} onClick={() => setTab(1)}>Send Mail</div>
        <div className='admin-tab' style={tab === 6 ? {border: '1px solid white'} : {}} onClick={() => setTab(6)}>Master Applications</div>
      </div>
      {tab === 0 ? <AllUsers getUsers={getUsers} usrs={usrs} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 7 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => u.paidStatus)} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 8 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => (!u.approved || !u.kycVerified)).filter(u => !!u.kycFile)} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 1 ? <SendMailToAll /> : <></>}
      {tab === 6 ? <MasterApplications /> : <></>}
      {tab === 2 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => u.paid)} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 3 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => (u.approved && u.kycVerified))} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 4 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => !u.paid)} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
      {tab === 5 ? <AllUsers getUsers={getUsers} usrs={usrs.filter(u => (!u.approved || !u.kycVerified))} approveUser={approveUser} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} setPaidStatus={setPaidStatus} /> : <></>}
    </>
  );
}