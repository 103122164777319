import React,{useEffect,useRef,useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import close from "../../images/close.svg";
import Select from "react-select";
import { Button, IconButton, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { disableIllegalCharacters, disablePaste } from '../../helpers';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'rgb(26, 26, 26)'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'rgb(26, 26, 26)',
      color: '#FFF',
      "&:hover": {
        backgroundColor: "#FFF",
        color: 'black'
      }
    };
  },
  menuList: (styles) => ({...styles, backgroundColor: 'rgb(26, 26, 26)'}), 
  singleValue: provided => ({
    ...provided,
    color: 'white'
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '1em',
    color: '#fff',
    fontWeight: 400,
  }),
  input: (base, state) => ({
    ...base,
    '[type="text"]': {
      color: '#FFF !important'
    }
  })
};

const CountrySelect = ({defaultValue, setSelectedCountry, selectedCountry, disabled}) => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);
  return (
    <Select
      options={countries}
      isDisabled={disabled}
      styles={colourStyles}
      value={Object.keys(selectedCountry).length > 0 ? selectedCountry : ''}
      placeholder={<div>Select Country</div>}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: '#FFF'
        },
      })}
      onChange={(selectedOption) => {setSelectedCountry(selectedOption)}}
    />
  );
};


export const KycModal = ({selectedCountry, setSelectedCountry, show, closeModal, name, idType, faceFile, file, country, isAdmin, kycFaceFileChange, kycFileChange, submitKYC, uid}) => {
  let [filename, setFilename] = useState('');
  let [faceFilename, setFaceFilename] = useState('');
  let [docType, setDocType] = useState('ID Card');
  useEffect(() => {
    setSelectedCountry(country ? JSON.parse(country) : {})
  }, [country]);
  return(
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Body>
        {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

        <div className="connectModal kycModal1 pt-0">
          <img className='closeIco' src={close} alt="" onClick={closeModal} />
          <h2>Fill out KYC</h2>
          {/* <div className="pt-2 pb-4">
            <div className="hrLine"></div>
          </div> */}
          <div className="hrLine my-3">
          </div>
          <div className="exchangeMain" style={{flexDirection: 'column'}}>
            <div className="my-3">
                {/* <label>Full Name</label><br/> */}
                <input className='w-100 input' id="kyc-name" {...disablePaste} {...disableIllegalCharacters} type="text" defaultValue={name || ''} disabled={isAdmin} placeholder='Full Name' />
            </div>
            <div className="my-3">
                {/* <label>Country</label><br/> */}
                {/* <input style={{
                  width: '100%', background: '#1A1A1A', border: '0px', color: 'white', padding: '15px',
                  borderRadius: '7px'
                }} id="kyc-country" type="text" defaultValue={country || ''} disabled={isAdmin} /> */}
                <CountrySelect disabled={isAdmin} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
            </div>
            <div className='my-3' style={{borderRadius: '50px', padding: '13px 30px', background: 'var(--themeBg)'}}>
              {isAdmin ? (
                <div>
                  <input className='w-100 input' id="kyc-id-type" type="text" value={idType} disabled={true} />
                </div>
              ) : (
                <select onChange={(e) => setDocType(e.target.value)} className='w-100 idType' id='kyc-id-type'>
                  <option value="" disabled selected>ID Type</option>
                  <option value="Passport">Passport</option>
                  <option value="Driving License">Driving License</option>
                  <option value="ID Card">ID Card</option>
                </select>
              )}
            </div>
            <div className="my-3 inputFile">
              <label>{docType ? docType : 'ID Card'}</label><br/>
              {file ? ( 
                <>
                  <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/${file}`}>KYC File</a>
                </>
              ) : (
                <>
                {isAdmin ? (<></>) : (
                <>
                <div style={{display: 'inline-block'}} onClick={() => document.getElementById('kyc-file').click()} className="themeBtn">Choose File</div> {' '}{filename}
                <input style={{display: 'none'}} className='w-100 input py-1 pl-0 br-0' id="kyc-file" accept="application/pdf,image/png,image/jpeg,image/jpg" type="file" onChange={(e) => {
                  if (e.target.files.length > 0){
                    setFilename(e.target.files[0].name);
                    kycFileChange(e);
                  }
                }} />
                </>
                )}
                </>
              )} 
            </div>
            <div className="my-3 inputFile">
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <label>{docType ? docType : 'ID Card'} with Face</label>
                <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 1000 }}
                title={<span style={{fontSize: '14px', textAlign: 'center', width: 'fit-content'}}>Upload a picture with your face visible and the document in your hand.</span>}>
                  <span style={{
                    textTransform: 'math-auto', borderRadius: '50%', border: '1px solid',
                    width: '25px',
                    height: '27px',
                    textAlign: 'center',
                    cursor: 'pointer'  
                  }}>i</span>
                </Tooltip>
              </div>

              {faceFile ? ( 
                <>
                  <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/${faceFile}`}>KYC File with face</a>
                </>
              ) : (
                <>
                {isAdmin ? (<></>) : (
                <>
                <div style={{display: 'inline-block'}} onClick={() => document.getElementById('kyc-face-file').click()} className="themeBtn">Choose File</div> {' '}{faceFilename}
                <input style={{display: 'none'}} className='w-100 input py-1 pl-0 br-0' id="kyc-face-file" accept="application/pdf,image/png,image/jpeg,image/jpg" type="file" onChange={(e) => {
                  if (e.target.files.length > 0){
                    setFaceFilename(e.target.files[0].name);
                    kycFaceFileChange(e);
                  }
                }} />
                </>
                )}
                </>
              )}
            </div>
          </div>
          {isAdmin ? (
            <>
            <div className="continueBtn text-center">
              <button className="themeBtnHome mr-2" onClick={() => submitKYC(uid, false)}>Reject</button>
              <button className="themeBtnHome" onClick={() => submitKYC(uid, true)}>Approve</button>
            </div>

            </>
          ) : (file ? (
            <div className="continueBtn text-center">
              <button className="themeBtnHome" onClick={closeModal}>Close</button>
            </div>
            ) : (
            <div className="continueBtn text-center">
              <button className="themeBtnHome" onClick={submitKYC}>Continue</button>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>

  );
}