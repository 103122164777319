import React, { useState } from 'react';

const limit = 10;
export default function AllUsers({usrs, getUsers, approveUser, setKycUserAdmin, openKYCFormAdmin, setPaidStatus}){
  const [filter, setFilter] = useState('email');
  const [filterValue, setFilterValue] = useState('');
  const pages = parseInt(usrs.length/10)+1;
  const [page, setPage] = useState(0);
  const filtered = (u) => {
    if (filterValue){
      return u[filter].includes(filterValue);
    }
    else{
      return true;
    }
  }
  let userss = usrs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).filter((u, i) => filterValue ? filtered(u) : ((limit*(page) <= i) && (i < limit*(page+1))));
  const prevPage = () => {
    if (page === 0){
    }
    else{
      setPage(page-1);
    }
  }
  const nextPage = () => {
    if (page === pages-1){
    }
    else{
      setPage(page+1);
    }
  }
  return(
    <>
      <div className='mt-3'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div>
              <label>Filter users</label>
              <input type="text" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} />
              <select defaultValue={'email'} onChange={e => {setFilter(e.target.value); setFilterValue('')}}>
                <option value={'email'}>Email</option>
                <option value={'username'}>Username</option>
                <option value={'whatsapp'}>Whatsapp</option>
              </select>
            </div>
            <div style={{marginLeft: '10px'}}>
              <button onClick={() => getUsers(localStorage.getItem('token'))}>Refresh Users</button>
            </div>
          </div>

          <div>
            <span>Page Num: {page+1}/{pages}</span>
            <button className='mx-3' onClick={prevPage}>Prev</button>
            <span>Showing {(page*limit)+1}-{(page*(limit))+10} out of {usrs.length} users </span>
            <button className='ml-3' onClick={nextPage}>Next</button>
          </div>
        </div>
        <table style={{width: '100%'}}>
          <thead>
            <th>Full Name</th>
            <th>Email</th>
            <th>Whatsapp</th>
            <th>Paid</th>
            <th>Always Paid</th>
            <th>Status</th>
            <th>KYC Status</th>
            <th>KYC Details</th>
            <th>Action</th>
          </thead>
          <tbody>
            {userss.map(u => {
              return(
                <tr style={{border: '1px solid grey'}} key={`admin-${u._id}`}>
                  <td style={{ padding: '10px' }}>{u.username}</td>
                  <td style={{ padding: '10px' }}>{u.email}</td>
                  <td style={{ padding: '10px' }}>{u.whatsapp ? u.whatsapp : 'Not provided'}</td>
                  <td style={{ padding: '10px' }}>{u.paid ? 'Paid' : 'Not paid'}</td>
                  <td style={{ padding: '10px' }}>
                    <input type='checkbox' defaultChecked={u.paidStatus} onChange={(e) => {setPaidStatus( u._id, e.target.checked)}} />
                  </td>
                  <td style={{ padding: '10px' }}>{u.approved ? 'Approved' : 'Unapproved'}</td>
                  <td style={{ padding: '10px' }}>{u.kycVerified ? 'Verified' : 'Not Verified' }</td>
                  <td onClick={() => {
                    if (u.kycFile){
                      setKycUserAdmin(u);
                      openKYCFormAdmin();
                    }
                  }} style={u.kycFile ? {textDecoration : 'underline', cursor: 'pointer', padding: '10px' } : {padding: '10px'}}>{u.kycFile ? 'Uploaded' : 'Not uploaded' }</td>
                  <td><button onClick={() => approveUser(u._id, !u.approved)}>Change</button></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

