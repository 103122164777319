import React from "react";
import { useSelector } from "react-redux";
import { showBalance } from "../../helpers";

export default function AssetsTable () {
  let balances = useSelector(s => s.balances);
  let authedUser = useSelector(s => s.authedUser);

  return(
    <div className="br-10 h-100 ">
      <div className="assetTable mt-0">
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col" style={{padding: '0.75rem'}}>#</th>
                <th scope="col" style={{padding: '0.75rem'}}>Name</th>
                <th scope="col" style={{padding: '0.75rem'}}>Available </th>
                <th scope="col" style={{padding: '0.75rem'}}>Reserved </th>
                <th scope="col" style={{padding: '0.75rem'}}>Total Value $ </th>
              </tr>
            </thead>
            <tbody>
                {balances.balances.sort((a, b) => {
                  if ((parseFloat(b.free)*b.usdPrice) < (parseFloat(a.free)*a.usdPrice)){
                    return -1;
                  }
                  else{
                    return 1;
                  }
                }).map((b, ind) => {
                  return(
                    <tr>
                      <td style={{padding: '0.75rem'}} scope="row">{ind+1}</td>

                      <td style={{padding: '0.75rem'}}>
                        <div className="nameMain">
                          <div className="imgDiv">
                            <img src={`https://copytrade.tenup.io/icons/${b.asset}.png`}  alt="" />
                          </div>
                          <div>
                            <div className="currency">{b.asset}</div>
                            <div className="sf">{b.asset}</div>
                          </div>
                        </div>
                      </td>
                      <td style={{padding: '0.75rem'}}>
                        <div className='balanceMain'>
                          <div className="balance" style={{fontSize: '13px'}}>{showBalance(parseFloat(b.free).toFixed(4), authedUser.authedUser.balanceHide)}</div>
                        </div>
                      </td>
                      <td style={{padding: '0.75rem'}}>
                        <div className='balanceMain'>
                          <div className="balance" style={{fontSize: '13px'}}>{showBalance(parseFloat(b.locked).toFixed(4), authedUser.authedUser.balanceHide)}</div>
                        </div>
                      </td>
                      <td style={{padding: '0.75rem'}}>
                        <div className='priceMain'>
                          <div className="usd" style={{fontSize: '13px'}}>{`$${showBalance(((parseFloat(b.free)+parseFloat(b.locked))*b.usdPrice).toFixed(2), authedUser.authedUser.balanceHide)}`}</div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}