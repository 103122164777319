import React,{useEffect, useState} from 'react';
import { BrowserRouter, BrowserRouter as Router,Redirect,Route,Switch } from "react-router-dom";

import './App.css'
import './component/global/global.css'
import './component/dashboard/dashboard.css'
// import './component/pump/loads.css'
import './component/company/company.css'
import cImg from './images/webp/connectImg.webp'

import $ from 'jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/jquery.easing/jquery.easing';

import '../node_modules/popper.js/dist/popper'

// route
import Main from './main';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { bscTestnet, mainnet } from '@reown/appkit/networks'


const store = ConfigureStore();

// 1. Get projectId
const projectId = 'f853986d6b3e2d20ada85d38244d8dc9';

// 2. Set the networks
const networks = [mainnet];

// 3. Create a metadata object - optional
const metadata = {
  name: 'Copytrade',
  description: 'Copytrading Platform',
  url: 'https://copytrade.tenup.io', // origin must match your domain & subdomain
  icons: ['https://copytrade.tenup.io/favicon.ico']
}
const ethersAdapter = new EthersAdapter()

// 4. Create a AppKit instance
createAppKit({
  adapters: [ethersAdapter],
  networks,
  // defaultNetwork: mainnet,
  metadata,
  projectId,
  allowUnsupportedChain: false,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    email: false,
    socials: [],
  },
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0"
  ]
})

function App() {
  const [isLogin,setIsLogin]= useState(false)

  useEffect(() => {
    const imagesToBePreloaded = [cImg]
    imagesToBePreloaded.forEach(image => { new Image().src = image })
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Router>
            <Main />
          </Router>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export {App};
