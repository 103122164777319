import React from 'react'
import closeIco from "../../images/close.svg"
import Modal from "react-bootstrap/Modal";
import { disableIllegalCharacters, disablePaste } from '../../helpers';

export const QnAModal = ({ show, handleClose, submit, data, admin }) => {
  const defaultsTo = (s) => s ? s : '';
  return (
    <Modal className='conditionModal kycModal1' show={show} onHide={handleClose} centered>
    <Modal.Body>
      <div className="otpModalMain">
        <div className="closeIcon" onClick={handleClose}>
          <img src={closeIco} alt="" />
        </div>
        <h2 className="title">Master Crypto Trader Application – Questionnaire</h2>
        <div className="conditionMain">
            <h3>Introduction</h3>
            <p>
              Welcome to the TenUp Copy Trading Platform! If you want to become a Master Trader on our
              platform, please answer the following questions within the specified time. Your answers will be
              evaluated to determine whether you qualify to join the **TenUp Master Trader Community**.
            </p>

            <h3>Personal Information</h3>
            <div>
              <div className='mb-2'>
              {/* qna1_name, qna1_email, qna1_exp, qna1_platform, qna1_currencies */}
                <label>Full Name</label> <br/>
                <input
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-1-name' defaultValue={defaultsTo(data?.qna1_name)} className='w-100 input' type="text" />
              </div>
              <div className='mb-2'>
                <label>Email</label> <br/>
                <input
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-1-email' defaultValue={defaultsTo(data?.qna1_email)} className='w-100 input' type="text" />
              </div>
              <div className='mb-2'>
                <label>Trading Experience (In Years)</label> <br/>
                <input
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-1-exp' defaultValue={defaultsTo(data?.qna1_exp)} className='w-100 input' type="text" />
              </div>
              <div className='mb-2'>
                <label>Preferred Trading Platform (e.g: Bitget, Binance, Okex)</label> <br/>
                <input
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-1-platform' defaultValue={defaultsTo(data?.qna1_platform)} className='w-100 input' type="text" />
              </div>
              <div className='mb-2'>
                <label>Primary Cryptocurrencies traded (e.g: BTC, ETH,..)</label> <br/>
                <input 
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-1-currencies' defaultValue={defaultsTo(data?.qna1_currencies)} className='w-100 input' type="text" />
              </div>
            </div>

            <h3>Trading Knowledge & Experience</h3>
            <div>
              {/* qna2_strategy, qna2_risk, qna2_leverage, qna2_analysis, qna2_trade_example, qna2_volatile */}
              <div className='mb-2'>
                <label>Describe your trading strategy in detail</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-2-strategy' defaultValue={defaultsTo(data?.qna2_strategy)} rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>How do you manage risk in your trading?</label> <br/>
                <textarea rows="4" className='w-100' type="text"
                id='qna-2-risk'
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna2_risk)}
                placeholder='Explain your approach to stop-loss, position sizing, and portfolio diversification'
                />
              </div>
              <div className='mb-2'>
                <label>Have you used leverage in your trading before? If so, explain how you manage leveraged
                positions</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-2-leverage'
                defaultValue={defaultsTo(data?.qna2_leverage)}
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>How do you conduct market analysis (technical/fundamental)? Please provide an example
                of how you make decisions based on these analyses.</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna2_analysis)}
                id='qna-2-analysis'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>Provide an example of a highly profitable trade you’ve made and what factors led to your
                success.</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna2_trade_example)}
                id='qna-2-trade-example'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>How do you react to market volatility? Explain your strategy for managing trades during
                sudden market shifts.</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-2-volatile'
                defaultValue={defaultsTo(data?.qna2_volatile)}
                rows="4" className='w-100' type="text" />
              </div>
            </div>


            <h3>Performance & Metrics</h3>
            <div>
              {/* qna3_roi, qna3_ratio, qna3_trades, qna3_loss */}
              <div className='mb-2'>
                <label>What is your average monthly ROI (Return on Investment)?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna3_roi)}
                placeholder="Provide approximate percentages and examples if possible." 
                id='qna-3-roi'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>What is your typical win/loss ratio?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna3_ratio)}
                id='qna-3-ratio'
                rows="4" className='w-100' type="text"
                placeholder='Number of winning trades compared to losing trades'
                />
              </div>
              <div className='mb-2'>
                <label>How many trades do you typically execute per day/week?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna3_trades)}
                id='qna-3-trades'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>Describe a time when you experienced a major loss. How did you handle it, and what did
                you learn from it?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna3_loss)}
                id='qna-3-loss'
                rows="4" className='w-100' type="text" />
              </div>
            </div>

            <h3>Crypto Market Understanding</h3>
            <div>
              <div className='mb-2'>
                <label>How do you stay updated on crypto market trends, news, and emerging opportunities?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna4_updated)}
                id='qna-4-updated'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>Explain your understanding of blockchain technology and how it impacts the crypto
                market.</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna4_understanding)}
                id='qna-4-understanding'
                rows="4" className='w-100' type="text"
                />
              </div>
              <div className='mb-2'>
                <label>Which technical indicators do you most frequently rely on when trading, and why?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna4_indicators)}
                id='qna-4-indicators'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>Do you have experience in trading altcoins or less popular cryptocurrencies? If so, which
                ones and why?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna4_altcoins)}
                id='qna-4-altcoins'
                rows="4" className='w-100' type="text" />
              </div>
            </div>

            <h3>Ethics & Responsibility</h3>
            <div>
              {/* qna5_principles, qna5_why, qna5_benefit */}
              <div className='mb-2'>
                <label>What ethical principles do you follow as a trader?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                id='qna-5-principles'
                defaultValue={defaultsTo(data?.qna5_principles)}
                placeholder='Consider how you approach transparency, fairness, and responsibility.'
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <label>Why do you want to become a Master Trader on TenUp Copy Trading Platform?</label> <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna5_why)}
                id='qna-5-why'
                rows="4" className='w-100' type="text"
                />
              </div>
              <div className='mb-2'>
                <label>How would you ensure that the followers who copy your trades benefit from your trading
                strategies?</label> <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna5_benefit)}
                id='qna-5-benefit'
                rows="4" className='w-100' type="text" />
              </div>
            </div>

            <h3>Scenarios</h3>
            <div>
              <div className='mb-2'>
                <strong>Scenario 1:</strong>
                <p>Bitcoin's price suddenly drops by 10% in one hour. What would your immediate trading
                response be, and how would you adjust your strategy?</p>
                <br/>
                <textarea
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna6_scenario1)}
                id='qna-6-scenario1' 
                rows="4" className='w-100' type="text" />
              </div>
              <div className='mb-2'>
                <strong>Scenario 2:</strong>
                <p>You've made a 20% profit on a trade, but the market is showing signs of further upward
                movement. How do you decide whether to close the position or continue holding it?</p>
                <br/>
                <textarea 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna6_scenario2)}
                id='qna-6-scenario2'
                rows="4" className='w-100' type="text" />
              </div>
            </div>

            <h3>Technical Skills</h3>
            <div>
              {/* qna7_platforms, qna7_automate, qna7_apis, */}
              <div className='mb-2'>
                <label>Which trading platforms and tools are you proficient in using?</label>
                <br/>
                <input 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna7_platforms)}
                id='qna-7-platforms'
                className='w-100 input' placeholder='TradingView, Binance, MetaTrader, etc' type="text" />
              </div>
              <div className='mb-2'>
                <label>Do you automate any part of your trading? If so, please describe the tools or strategies you
                use.</label>
                <br/>
                <input 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna7_automate)}
                id='qna-7-automate'
                className='w-100 input' type="text" />
              </div>
              <div className='mb-2'>
                <label>How familiar are you with APIs for trading platforms? Have you ever developed or used
                bots for trading?</label>
                <br/>
                <input 
                {...disablePaste}
                {...disableIllegalCharacters}
                defaultValue={defaultsTo(data?.qna7_apis)}
                id='qna-7-apis'
                className='w-100 input' type="text" />
              </div>
            </div>

            <h3>Final Thoughts</h3>
            <div>
              <div className='mb-2'>
                <label>What makes you stand out as a trader, and why do you think you would be a valuable
                addition to the TenUp Master Trader community?</label> <br/>
                <textarea 
                id='qna-8-final'
                defaultValue={defaultsTo(data?.qna8_final)}
                {...disablePaste}
                {...disableIllegalCharacters}
                rows="4" className='w-100' type="text" />
              </div>
            </div>

            <h3>Submission Guidelines:</h3>
            <p>- All submissions will be carefully evaluated by our team.</p>
            <p>- If selected, you will be invited to join the **TenUp Master Trader Community**.</p>


        </div>
        <div className="btnMain mt-4">
            {!admin && <button
            type="submit"
            className="themeBtn "
            onClick={submit}
            >
            Submit
            </button>}
            <button
            type="submit"
            className="outlineBtn ml-4" 
            onClick={handleClose}
            >
            Cancel
            </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}
