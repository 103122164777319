import React, { useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from 'react-detect-click-outside';

import searchIco from "../../../images/searchIco.svg";
import notifyIco from "../../../images/notifyIco.svg";
import dropdownIcon from "../../../images/dropdown-icon.svg";
import profile from "../../../images/profile.jpg";
import profileBorder from "../../../images/webp/profileBorder.webp";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, updateUser } from "../../../redux/ActionCreators";
import { useHistory, Link, NavLink,useLocation } from "react-router-dom";
import {ReactComponent as ShowBalanceIco} from "../../../images/showBalance.svg";
import {ReactComponent as HideIco} from "../../../images/hideBalance.svg";
import {ReactComponent as PortfolioIco} from "../../../images/portFolio.svg";
import {ReactComponent as DiaryIco} from "../../../images/diary.svg";
import {ReactComponent as SubscriptionIco} from "../../../images/subscription.svg";
import {ReactComponent as InviteIco} from "../../../images/share.svg";
import {ReactComponent as SettingIco} from "../../../images/setting.svg";
import {ReactComponent as ApiIco} from "../../../images/api.svg";
import {ReactComponent as LogoutIco} from "../../../images/logout.svg";
import { showBalance } from "../../../helpers";
import axios from "axios";
import { toast } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { shutdown } from '@intercom/messenger-js-sdk';

function Navbar() {
  const balances = useSelector(state => state.balances);
  const authedUser = useSelector(state => state.authedUser);
  const [showDrop, setShowDrop] = useState(false);
  const [livePrice, setLivePrice] = useState({btc_price: 0, eth_price: 0});
  const [totalB, setTotalB] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { lastMessage, readyState } = useWebSocket(process.env.REACT_APP_SOCKET, {
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastMessage?.data && readyState === ReadyState.OPEN) {
      const coinData = JSON.parse(lastMessage.data);
      setLivePrice({
        ...coinData?.data,
        change24hBtc: parseInt(coinData?.data.btc_price) - parseInt(livePrice?.btc_price),
        change24hEth: parseInt(coinData?.data.eth_price) - parseInt(livePrice?.eth_price)
      });
    }
  }, [lastMessage?.data, readyState]);

  const closeDropDown = () =>{
    if(showDrop){
      setShowDrop(false)
    }
  }
  const wrapperRef = useDetectClickOutside({ onTriggered: closeDropDown });


  const logout = () => {
    dispatch(logoutUser());
    history.push("/login");
    shutdown();
  }

  const hideUserBalance = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/balanceHide`, {
      method: 'PUT',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": 'application/json'
      }
    }).then(res => res.json())
    .then(res => {
      if (res.success){
        return dispatch(updateUser(res.user));
      }
      else{
        toast('Failed', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    })
  }

  const handleShowDrop = ()=> {
    if(!showDrop){
      setShowDrop(true)
    }
  }

  // const handlelivePrice = async () => {
  //   try {
  //       const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/crypto-live-price`)
  //           .then(res => {
  //               setLivePrice(res?.data?.message)
  //           })
  //   }
  //   catch (err) {
  //       console.log(err.response.data);
  //   }
  // }

  useEffect(() => {
    // socket.on('btc_eth_price', (data) => {
    //   console.log(data);
    // })
    // setInterval(() => {
    //   handlelivePrice()
    // }, 12000);
  }, [])

  useEffect(() => {
    let t = 0;
    balances.balances.forEach(b => {
      console.log(b);
      t += (parseFloat(b.free)+parseFloat(b.locked))*b.usdPrice
    });
    console.log(t);
    setTotalB(t);
  }, [balances]);
  return(
    <>
          {/* Topbar */}
          <nav className="navbar navbar-expand navbar-light  topbar mb-4 static-top">
            <div className="navHead capitalize">
              <div className="navBalanceRow">
                {authedUser?.authedUser?.admin ? (<></>) : (<>
                
                  <div className="priceCol cardBg cardBorder">
                    <div className="title">Real Account <span className="colorGreen">-0.21%</span></div>
                      <div className="price">${(showBalance(totalB?.toFixed(2), authedUser.authedUser.balanceHide))} 
                      <span style={{fontWeight: '800', fontSize: '12px'}}> ~ {(showBalance(balances?.total?.toFixed(2), authedUser.authedUser.balanceHide)) / parseFloat(livePrice?.btc_price) ||0} BTC</span>
                    </div>
                  </div>
                  <div className="priceCol">
                  {/* .colorRed {
      color: #FC0606;
   }
   .colorGreen {
      color: #67E1AE;
   } */}
                    <div className="title" style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => authedUser?.authedUser?.master ? history.push('/copytrade') : ''}>BTC/USDT</div>
                    <div style={{color: livePrice?.change24hBtc < 0 ? '#FC0606' : '#67E1AE'}} className={`price`}>${livePrice?.btc_price || 0} 
                      {/* <span className={livePrice?.change24hBtc < 0 ?"colorRed":"colorGreen" } >{livePrice?.change24hBtc?.toFixed(2) ||0}%</span> */}
                    </div>
                  </div>
                  <div className="priceCol">
                    <div className="title" style={{ cursor: 'pointer', fontWeight: '700' }} onClick={() => authedUser?.authedUser?.master ? history.push('/copytrade') : ''}>ETH/USDT</div>
                    <div style={{color: livePrice?.change24hEth < 0 ? '#FC0606' : '#67E1AE'}} className={`price`}>${livePrice?.eth_price || 0} 
                      {/* <span className={livePrice?.change24hEth < 0 ?"colorRed":"colorGreen" } >{livePrice?.change24hEth?.toFixed(2) ||0}%</span> */}
                    </div>
                  </div>
                </>)}
              </div>
            </div>
            {/* Sidebar Toggle (Topbar) */}
            {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars" />
            </button> */}
            {/* Topbar Search */}
            {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
              <div className="input-group">
                <input type="text" className="form-control theme-bg-color border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                <div className="input-group-append">
                  <button className="btn search-btn pr-4" type="button">
                    <img src={searchIcon} className="w-15 mb-1" alt="serach icon" />
                  </button>
                </div>
              </div>
            </form> */}
            {/* Topbar Navbar */}
             <ul className="navbar-nav ml-auto">

              {/* Nav Item - User Information */}
              <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="img-profile">
                    <img className="" src={authedUser.authedUser.profilepic ? `${process.env.REACT_APP_BASE_URL}/${authedUser?.authedUser?.profilepic}` : profile} />
                  </div>
                  <div className="profile-div ml-2">
                    <span className="sidebar-item mr-2 small profile-name">{authedUser.authedUser.username}</span>
                    {/* <span className="status">Active</span> */}
                  </div>
                  <div>
                    <img src={dropdownIcon} className="w-9 ml-1 " alt="dropdown icon" />
                  </div>


                </a>
                {/* Dropdown - User Information */}
                <div className="dropdown-menu themeCardGlobal dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <a className="dropdown-item text-center" href="#">
                    {authedUser.authedUser.email}
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" onClick={hideUserBalance} className="dropdown-item" >
                    <span className="dib mr-2">  
                      {authedUser.authedUser?.balanceHide?<ShowBalanceIco />:<HideIco />} 
                    </span>
                    {authedUser.authedUser?.balanceHide?"Show balance":"Hide balance"}
                    
                  </a>
                  {/* <NavLink className="dropdown-item" to="/traders-diary">
                    <span className="dib mr-2"> <DiaryIco /></span>
                    Trader's Diary
                  </NavLink> */}
                  <div className="dropdown-divider" />
                  {/* <a className="dropdown-item" href="#">
                    <span className="dib mr-2"> <InviteIco /></span>
                    Invite Friends
                  </a> */}
                  <Link className="dropdown-item" to="/settings">
                    <span className="dib mr-2">  <SettingIco /></span>
                    Settings
                  </Link>
                  {/* <a className="dropdown-item" href="#">
                    <span className="dib mr-2"> <ApiIco /></span>
                    API
                  </a> */}
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#" onClick={logout} data-toggle="modal" data-target="#logoutModal">
                    <span className="dib mr-2"> <LogoutIco /></span> 
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </nav>
          {/* End of Topbar */}
    </>
  )
}

export default Navbar