/* global BigInt */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/ActionCreators";
import ERC20 from '../../abis/erc20.json';
import { Web3 } from 'web3';
import { Loader } from "../loader/loader";
import metamask from "../../images/metamask.png";
import close from "../../images/close.svg";
import Modal from 'react-bootstrap/Modal';

export const PaymentFormComp = () => {
  const dispatch = useDispatch();
  const authedUser = useSelector(state => state.authedUser);
  const [loader, setLoader] = useState(false);
  const [connectWModal, setConnectWModal] = useState(false); 
  const [ pendingExists, setPendingExists ] = useState(false);

  const getPendingExists = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/pendingTx/exists`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json()).then(r => {
      if (r.success){
        setPendingExists(true)
      }
    })
  }

  useEffect(() => {
    getPendingExists()
  }, [])

  const submitTx = () => {
    const txHash = document.getElementById('pay-tx-hash').value;

    if (!txHash){
      toast('Tx Hash is required', {
        theme: 'dark',
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    addPendingTx(txHash)
    
    toast(`Tx Hash submitted successfully. Check in next few minutes`, {
      theme: 'dark',
      position: toast.POSITION.BOTTOM_RIGHT
    });
    setConnectWModal(false)
    setTimeout(() => {
      getPendingExists()
    }, 1000)
  }

  const getBalance = async (adrs, tok) => {
    let blnc = await tok.methods.balanceOf(adrs).call();
    let dec = await tok.methods.decimals().call();
    console.log(blnc,dec);
    return [blnc/((10n)**dec), dec]
  }

  const addPendingTx = (txHash) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/pendingTx`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': `application/json`
      },
      body: JSON.stringify({
        endpoint: '/users/payment',
        method: 'PUT',
        txHash,
      })
    })
  }

  const payNow = async () => {
    try {
      setLoader(true);
      let accs = [], web3;
      if (!window.ethereum){
        setLoader(false);
        setConnectWModal(false);
        toast('Install Metamask', {
          theme: 'dark',
          position: 'bottom-right'
        });
      }
      else if (parseInt(window?.ethereum?.chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)){
        setLoader(false);
        setConnectWModal(false);
        toast('Connect to Ethereum mainnet', {
          theme: 'dark',
          position: 'bottom-right'
        });
      }
      else{
        try{
          accs = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          web3 = new Web3(window.ethereum);
        }
        catch(e){
          setLoader(false);
          setConnectWModal(false);
          toast('Login Failed', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
        }
      }
      if (accs.length > 0){
        let acc = accs[0];
        let tok = new web3.eth.Contract(ERC20, process.env.REACT_APP_PAYMENT_TOKEN);
        let [b, dec] = await getBalance(acc, tok);
        if (parseInt(b) < parseInt(process.env.REACT_APP_PAYMENT_FEES)){
          setLoader(false);
          setConnectWModal(false);
          toast('Insufficient Balance', {
            theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT
          });
        }
        else{
          try{
            let r = await tok.methods.transfer(process.env.REACT_APP_PAYMENT_ADDRESS, parseInt(process.env.REACT_APP_PAYMENT_FEES)*(10**parseInt(dec))).send({
              from: acc
            }).on('transactionHash', (txHash) => {
              addPendingTx(txHash)
            });
            let res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/payment`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({txHash: r.transactionHash})
            }).then(r => r.json());
            if (res.success){
              dispatch(updateUser({...authedUser.authedUser, paid: true }));
            }
            else{
              toast(res.msg, {
                theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT
              });
            }
            setLoader(false);
            setConnectWModal(false);
          }
          catch(e){
            setLoader(false);
            setConnectWModal(false);
            console.log(e);
            toast('Failed to make payment', {
              theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT
            });
          }
        }
      }
    }
    catch(e){
      setLoader(false);
      setConnectWModal(false);
      console.log(e);
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  return(
    <div className="row">
      {/* {selectedPlan === 0 ? (
      <>{Object.keys(plans).map((plan,i) => (
        <PaymentPlan setPaymentMethod={setPaymentMethod} setSelectedPlan={setSelectedPlan} key={`${i}-${plan.header}-${i}`} plan={plans[plan]} />
      ))}</>
      ) : ( */}
        <div className="connectExMain col-12 text-center" style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 style={{color: 'var(--themeGreen)'}}>Subscription Offer</h4>
                <p>Unlock 90-day access to our copy trading platform for just 3,000 TenUp tokens. Simply connect
                your MetaMask wallet and pay with TenUp to subscribe and start enjoying the benefits immediately.</p>
                <p style={{textAlign: 'center'}}>How to Subscribe:</p>
                <p style={{textAlign: 'center', marginBottom: '0px'}}>1. Connect your MetaMask wallet.</p>
                <p style={{textAlign: 'center', marginBottom: '0px'}}>2. Pay 3,000 TenUp tokens.</p>
                <p style={{textAlign: 'center'}}>3. Gain instant access to copy trading for the next 90 days.</p>
                <p>Take advantage of this offer and elevate your trading experience today!</p>
                {pendingExists ? <p style={{color: 'red', fontStyle: 'italic'}}>Your payment is being processed. Please wait patiently</p> : 
                <button className="themeBtn" onClick={() => setConnectWModal(true)} style={{margin: 'auto'}}>Pay Now</button>
                
                }
              </div>
            </div>
          </div>
        </div>
      {/* )}  */}
      {loader ? <Loader loader={true} text={'Dont close the window..'} /> : <></>}
      <Modal show={connectWModal} onHide={() => setConnectWModal(false)} centered>
        <Modal.Body>
          <div className="connectModal pb-0 pt-0">
            <img className='closeIco' src={close} alt="" onClick={() => setConnectWModal(false)} />
            <h2>Connect Wallet</h2>
            <div className="pt-2 pb-4">
              <div className="hrLine"></div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              height: '120px',
              cursor: 'pointer'
            }} onClick={payNow}>
              <img style={{
                borderRadius: '20px',
                boxShadow: '0 3px 10px hsl(232.62deg 64.4% 69.03% / 32%)',
                padding: '20px',
                transition: 'all .3s'
              }} src={metamask} />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px'
            }} >OR</div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '10px'
            }}>
              <h5>Transfer 3000 TUP to the following address and submit Tx Hash below:</h5>
              <p>{process.env.REACT_APP_PAYMENT_ADDRESS} 
                <span style={{
                  marginLeft: '10px',
                  border: '1px solid',
                  borderRadius: '10px',
                  padding: '2px 10px',
                  cursor: 'pointer'
                }} onClick={() => {
                  navigator.clipboard.writeText(process.env.REACT_APP_PAYMENT_ADDRESS)
                  toast('Copied', {
                    theme: 'dark',
                    position: toast.POSITION.BOTTOM_RIGHT
                  })
                  }}>
                  Copy
                </span>
              </p>

              <input style={{
                width: '100%',
                background: 'none',
                border: 'none',
                borderBottom: '1px solid',
                height: '45px',
                color: 'white'
              }} type="text" id="pay-tx-hash" placeholder="Enter Tx Hash" />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '36px'
            }} onClick={submitTx}>
              <button className="themeBtn">Submit</button>
            </div>

            {/* <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '36px'
            }} onClick={() => setConnectWModal(false)}>
              <button className="themeBtn">Close</button>
            </div> */}
          {/* </div> */}
            {/* <div className="btnRightCut " onClick={()=>continueToExchange()}>
              <img className='' src={rightCutBtn} alt=""/>
              <span>Continue</span>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
