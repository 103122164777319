import React from 'react';
import "./landingPage.css";
import { Navbar } from './navbar';
import { Footer } from './footer';

export const Terms = () => {
  return (
    <>
    <div className="landingPage">
        <Navbar />
        <section className='sec1'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-12">
                    <h4 className='text-center'>
                      <strong>
                        Terms and Conditions for TENUP Copy Trading Platform
                      </strong>
                    </h4>
                    <p>
                    Last updated: 16.05.2024
                    </p>
                    <h5>
                      <strong>
                        1. Introduction
                      </strong>
                    </h5>
                    <p>
                    Welcome to the TENUP Copy Trading Platform, accessible via https://copytrade.tenup.io/.
                    These Terms and Conditions govern your access to and use of our services. By accessing or
                    using the Platform, you agree to be bound by these Terms and all applicable laws and
                    regulations.
                    </p>

                    <h5>
                      <strong>
                        2. Services Description
                      </strong>
                    </h5>
                    <p>
                      The TENUP Copy Trading Platform allows users to engage in copy trading, where users can
                      copy the trading strategies of other traders. Our services are provided on a non-custodial basis,
                      meaning we do not have access to or control over your wallet or assets.
                    </p>

                    <h5>
                      <strong>
                        3. Eligibility
                      </strong>
                    </h5>

                    <p>
                    You must be at least 18 years old to use our Platform. By agreeing to these Terms, you
                    represent and warrant that you are of legal age to form a binding contract with TENUP and meet
                    all of the foregoing eligibility requirements.
                    </p>

                    <h5>
                      <strong>
                        4. Account Registration
                      </strong>
                    </h5>

                    <p>
                    You may be required to register for an account to access some features of the Platform. You are
                    responsible for maintaining the confidentiality of your account password and for all activities that
                    occur under your account. TENUP reserves the right to refuse service, terminate accounts, or
                    remove or edit content in its sole discretion.
                    </p>

                    <h5>
                      <strong>
                        5. User Conduct
                      </strong>
                    </h5>

                    <p>
                    You agree to not engage in any of the following prohibited activities:
                    <ul>
                      <li>
                        Use the Platform for any unlawful purpose or in violation of any local, state, national, or
                        international law.
                      </li>
                      <li>
                        Violate or attempt to violate the security of the Platform.
                      </li>
                      <li>
                        Access or search the Platform by any means other than our publicly supported interfaces.
                      </li>
                      <li>
                        Interfere with or disrupt any user, host, or network, for example by sending a virus,
                        overloading, flooding, spamming, or mail-bombing the Platform.
                      </li>
                    </ul>
                    </p>


                    <h5>
                      <strong>
                        6. Intellectual Property
                      </strong>
                    </h5>
                    <p>
                      All content on the Platform, including but not limited to text, graphics, logos, and software, is the
                      property of TENUP or its licensors and is protected by copyright and other intellectual property
                      laws. You may not use, reproduce, or distribute any content from the Platform without our prior
                      written permission.
                    </p>

                    <h5>
                      <strong>
                        7. Disclaimer of Warranties
                      </strong>
                    </h5>
                    <p>

                    The Platform is provided on an "as is" and "as available" basis. TENUP expressly disclaims any
                    warranties of any kind, whether express or implied, including, but not limited to, the implied
                    warranties of merchantability, fitness for a particular purpose, and non-infringement.
                    </p>

                    <h5>
                      <strong>
                        8. Limitation of Liability
                      </strong>
                    </h5>
                    <p>

                    TENUP shall not be liable for any direct, indirect, incidental, special, consequential, or
                    exemplary damages resulting from your use of the Platform or for any other claim related in any
                    way to your use of the Platform.
                    </p>

                    <h5>
                      <strong>
                        9. Indemnification
                      </strong>
                    </h5>
                    <p>

                    You agree to defend, indemnify, and hold harmless TENUP and its officers, directors,
                    employees, and agents from and against any claims, damages, obligations, losses, liabilities,
                    costs or debt, and expenses arising from your use of and access to the Platform, including any
                    data or content transmitted or received by you.
                    </p>

                    <h5>
                      <strong>
                        10. Changes to Terms
                      </strong>
                    </h5>
                    <p>

                    TENUP reserves the right, at its sole discretion, to modify or replace these Terms at any time. If
                    a revision is material, we will provide at least 30 days' notice prior to any new terms taking
                    effect. What constitutes a material change will be determined at our sole discretion.
                    </p>

                    <h5>
                      <strong>
                        11. Termination
                      </strong>
                    </h5>
                    <p>

                    We may terminate or suspend your access to our Platform immediately, without prior notice or
                    liability, for any reason whatsoever, including, without limitation, if you breach the Terms.
                    </p>

                    <h5>
                      <strong>
                        12. Governing Law
                      </strong>
                    </h5>
                    <p>

                    These Terms shall be governed and construed in accordance with the laws of [Jurisdiction],
                    without regard to its conflict of law provisions.
                    </p>

                    <h5>
                      <strong>
                        13. Contact Us
                      </strong>
                    </h5>
                    <p>

                    If you have any questions about these Terms, please contact us at contact@tenup.io
                    By using the TENUP Copy Trading Platform, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms and Conditions
                    </p>

                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
    </>
  )
}
