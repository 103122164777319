const illegalCharacters = ['<', '>', '{', '}', '/', '(', ')'];

export const showBalance = (balance, balanceHide) => {
  return balanceHide ? "***" : balance
}

export const disableIllegalCharacters = {
  onKeyDown : (e) => {if (illegalCharacters.includes(e.key)){e.preventDefault()}}
}
export const disablePaste = {
  onPaste: (e) => {
    if (illegalCharacters.some(char => e.clipboardData.getData('Text').includes(char))){
      e.preventDefault();
    }
  }
}